import { $api } from '@/api/api'
import {
	IGetSponsorTopRatingParams,
	IGetTransactionInformationQuery,
	IPaymentRecordPayload,
	IResponsePaymentInvoice,
	ISponsorTopRating,
	ITransactionInformation,
} from '../types/services/Payment.type'
import { createQueryStringByParams } from '../helpers/createQueryStringByParams'

class paymentService {

	async createPaymentInvoice(
		payload: IPaymentRecordPayload
	): Promise<IResponsePaymentInvoice> {
		return (await $api.post('/payments', payload)).data
	}

	async getSponsorTopRating(
		payload: IGetSponsorTopRatingParams,
		apiKey?: string | null
	): Promise<ISponsorTopRating[]> {
		const queryString = createQueryStringByParams(payload)
		return (
			await $api.get(`/payments/top${queryString}`, { headers: { apiKey } })
		).data
	}

	async getTransactionInformation(query: IGetTransactionInformationQuery):Promise<ITransactionInformation[]> {
		const queryString = createQueryStringByParams(query);

		return (await $api.get(`/payments${queryString}`)).data
	}
}

export const PaymentService = new paymentService()
