import React from 'react';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import styles from './setUserSiteLink.module.scss';

import { IParticipant } from '@/lib/types/services/Competiton.type';
import Input from '@/components/UI/Input/Input';
import Button from '@/components/UI/Button/Button';
import { useModal } from '@/context/useModalContext';
import { ISetUserLinkPayload } from '@/lib/types/services/User.type';
import { UserService } from '@/lib/services/User.service';
import { queryClient } from '@/components/Providers/_QueryClientProvider';

interface ISetUserSiteLink {
    participant: IParticipant;
}

const SetUserSiteLink: React.FC<ISetUserSiteLink> = ({ participant }) => {
    const { togglePopup } = useModal();

    const {
        control,
        handleSubmit,
        formState: { isValid, isDirty, errors },
    } = useForm<{ userLink: string }>({
        mode: 'onChange',
        criteriaMode: 'all',
        defaultValues: {
            userLink: participant?.link || '',
        },
    });

    const { mutate, isPending } = useMutation({
        mutationFn: async (data: ISetUserLinkPayload) => await UserService.setUserLink(data),
        onSuccess: (data) => {
            togglePopup(false);
            queryClient.invalidateQueries({
                queryKey: ['getContestListQuery'],
            });
            queryClient.invalidateQueries({
                queryKey: ['getModerationListQuery'],
            });
        },
        onError: (data) => {
            toast.error('Произошла ошибка при попытке установить ссылку на профиль');
        },
    });

    const onSubmitForm: SubmitHandler<{ userLink: string }> = (data) => {
        mutate({
            link: data.userLink,
            user_id: participant.user_id,
        });
    };

    console.log(errors);

    return (
        <div className={styles.setUserSiteLinkModal}>
            <div className={styles.header}>
                <h2>Добавление ссылки на профиль Lalau.ru</h2>
                <small>
                    Укажите ссылку на профиль сайта Lalau.ru пользователя <span>{participant?.full_name}</span>
                </small>
            </div>

            <form onSubmit={handleSubmit(onSubmitForm)} className={styles.content}>
                <Controller
                    name="userLink"
                    control={control}
                    rules={{
                        required: true,
                        pattern: {
                            value: /^https:\/\/lalau\.ru\/user/,
                            message: 'Cсылка должна начинатся с https://lalau.ru/user...',
                        },
                        minLength: {
                            value: 40,
                            message: 'Минимальная длина 40 символов',
                        },
                    }}
                    render={({ field }) => (
                        <Input
                            placeholder={'https://lalau.ru/user/<user-hash>'}
                            value={field.value}
                            onChangeHandler={field.onChange}
                        />
                    )}
                />
                <ErrorMessage
                    errors={errors}
                    name={'userLink'}
                    render={({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <p style={{ color: 'red', fontSize: '0.875rem', textAlign: 'center' }} key={type}>
                                {message}
                            </p>
                        ))
                    }
                />

                <div className={styles.controls}>
                    <Button text={'Изменить'} isLoading={isPending} disabled={!isValid || !isDirty} />
                    <Button text={'Отмена'} onClick={() => togglePopup(false)} className="btn_cancel" />
                </div>
            </form>
        </div>
    );
};

export default SetUserSiteLink;
