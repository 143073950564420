'use client';

import React from 'react';
import Image from 'next/image';
import Video from 'next-video';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Zoom } from 'swiper/modules';
import { useSwipeable } from 'react-swipeable';

import 'swiper/css';
import styles from './photoGallery.module.scss';

import BackArrowSVG from '/public/img/backArrow.svg';
import { IGalleryPayload } from '@/lib/types/services/Competiton.type';
import { ImageTypes, VideoTypes } from '@/lib/helpers/fileTypes';
import { getTypeOfFileFromURL } from '@/lib/helpers/getTypeOfFileFromURL';

interface IPhotoGallery {
    closeHandler: () => void;
    payload: IGalleryPayload;
}

const PhotoGallery: React.FC<IPhotoGallery> = ({ closeHandler, payload }) => {
    const handlers = useSwipeable({
        onSwipedUp: () => closeHandler(),
    });

    React.useEffect(() => {
        const $adminDashboard = document.querySelector('.admin-dashboard');

        if ($adminDashboard) ($adminDashboard as HTMLDivElement).style.overflow = 'hidden';

        return () => {
            if ($adminDashboard) ($adminDashboard as HTMLDivElement).style.overflow = 'auto';
        };
    }, []);

    return (
        <div className={styles.wrapper} {...handlers}>
            <div className={styles.header}>
                <span onClick={closeHandler}>
                    <BackArrowSVG />
                </span>
                <p>{payload.full_name}</p>
            </div>

            <div className={styles.gallery}>
                <Swiper zoom={false} modules={[Zoom]}>
                    {payload.media.map((link, index) => (
                        <SwiperSlide key={index}>
                            <div className={styles.galleryItem} key={index}>
                                {ImageTypes.includes(getTypeOfFileFromURL(link)) ? (
                                    <div className="swiper-zoom-container">
                                        <Image
                                            src={link}
                                            priority
                                            width={500}
                                            height={700}
                                            alt="photo"
                                            loading="eager"
                                        />
                                    </div>
                                ) : VideoTypes.includes(getTypeOfFileFromURL(link)) ? (
                                    <Video src={link} />
                                ) : (
                                    ''
                                )}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default PhotoGallery;
