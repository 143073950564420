import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { FieldError } from 'react-hook-form';
import InputMask from 'react-input-mask';

import './input.scss';

import { useErrorHandler } from '../useErrorHandler';

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
    type?: 'text' | 'tel' | 'number' | 'date' | 'password';
    icon?: string;
    placeholder: string;
    value: string | number;
    onChangeHandler: Dispatch<SetStateAction<string>>;
    mask?: string;
    title?: string;
    errorForm?: FieldError;
}

const formatChars = {
    '9': '[0-9]',
    a: '[A-Za-z]',
    '*': '[A-Za-z0-9]',
    '-': '[A-Za-z0-9_]',
};

const Input: React.FC<IInput> = ({
    type,
    icon,
    placeholder,
    maxLength,
    inputMode,
    value,
    onChangeHandler,
    mask,
    title,
    errorForm,
    ...props
}) => {
    const { isError } = useErrorHandler(errorForm);

    return (
        <div className={'base-input'} {...props}>
            {title && <p className="base-input__title">{title}</p>}
            {mask ? (
                <InputMask
                    mask={mask}
                    placeholder={placeholder}
                    value={value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeHandler(event.target.value)}
                    maxLength={maxLength}
                    maskChar=" "
                    disabled={props.disabled}
                    formatChars={formatChars}
                    className={`base-input__style ${isError ? 'base-error' : ''}`}
                    inputMode={inputMode}
                />
            ) : (
                <input
                    type={type}
                    value={value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeHandler(event.target.value)}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    disabled={props.disabled}
                    className={`base-input__style ${isError ? 'base-error' : ''}`}
                    inputMode={inputMode}
                />
            )}
            {icon && <div className={'base-input__icon'}>{icon}</div>}
        </div>
    );
};

export default Input;
