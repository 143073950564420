import React from 'react';

import styles from './rejectParticipant.module.scss';
import Textarea from '@/components/UI/Textarea/Textarea';
import Button from '@/components/UI/Button/Button';
import { useMutation } from '@tanstack/react-query';
import { CompetitionsService } from '@/lib/services/Competitions.service';
import { queryClient } from '@/components/Providers/_QueryClientProvider';
import { IParticipant, ISetUserModerationStatus } from '@/lib/types/services/Competiton.type';
import { useModal } from '@/context/useModalContext';
import toast from 'react-hot-toast';

interface IRejectParticipant {
    participant: IParticipant;
}

const MAX_COMMENT_LENGTH = 128;

const RejectParticipant: React.FC<IRejectParticipant> = ({ participant }) => {
    const { togglePopup } = useModal();
    const [rejectComment, setRejectComment] = React.useState('');

    const { mutate, isPending } = useMutation({
        mutationFn: async (payload: ISetUserModerationStatus) =>
            CompetitionsService.setUserModerationStatus(payload, rejectComment),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['getContestListQuery'],
            });
            queryClient.invalidateQueries({
                queryKey: ['getModerationListQuery'],
            });
            togglePopup(false);
        },
        onError: () => {
            toast.error('Не удалось отклонить заявку');
        },
    });

    const reject = () => {
        if (!canReject) return;

        mutate({ survey_id: participant.id, grade: 'decline' });
    };

    const canReject = React.useMemo(() => {
        return rejectComment.trim().length >= 5 && rejectComment.trim().length <= MAX_COMMENT_LENGTH;
    }, [rejectComment]);

    return (
        <div className={styles['reject-action']}>
            <div className={styles.header}>
                <h2>Отклонить заявку</h2>
                <small>
                    Вы дейтсвительно хотите отклонить заявку от пользователя <span>{participant?.full_name}</span>?
                </small>
            </div>

            <div className={styles.content}>
                <Textarea
                    value={rejectComment}
                    onChange={setRejectComment}
                    placeholder={`Укажите причину(ы). Максимальная длина ${MAX_COMMENT_LENGTH} символов`}
                    showTextLength={true}
                    maxLength={MAX_COMMENT_LENGTH}
                />
            </div>

            <div className={styles.controls}>
                <Button text={'Отклонить'} isLoading={isPending} disabled={!canReject} onClick={reject} />
                <Button text={'Отмена'} onClick={() => togglePopup(false)} className="btn_cancel" />
            </div>
        </div>
    );
};

export default RejectParticipant;
