import React from 'react';
import { FieldError } from 'react-hook-form';

import { useErrorHandler } from '../useErrorHandler';

import './textarea.scss';

interface ITextarea {
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    errorForm?: FieldError;
    showTextLength?: boolean;
    maxLength?: number;
}

const Textarea: React.FC<ITextarea> = ({
    placeholder,
    value,
    errorForm,
    onChange,
    showTextLength = false,
    maxLength,
}) => {
    const { isError } = useErrorHandler(errorForm);

    return (
        <div className="base-textarea">
            <textarea
                className={`base-input__style ${isError ? 'base-error' : ''}`}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => onChange(event.target.value)}
                placeholder={placeholder}
                maxLength={maxLength}
            />
            {showTextLength && (
                <p>
                    {value.trim().length} / {maxLength}
                </p>
            )}
        </div>
    );
};

export default Textarea;
