import React from "react"
import { FieldError } from "react-hook-form"

export const useErrorHandler = (error?: FieldError) => {
  const isError = React.useMemo(() => {
    return error
  }, [error])

  return {
    isError
  }
}