import { $api } from '@/api/api';
import {
    IRegistrationParticipation,
    IRegistrationParticipationResponse,
    ISetUserLinkPayload,
    IUserCompetitionHistory,
    IUserCompetitionModerationList,
    IUserIPResponse,
} from '../types/services/User.type';
import { IApiResponse } from '../types/Api.type';

class userService {
    async createRequestParticipation(payload: IRegistrationParticipation): Promise<IRegistrationParticipationResponse> {
        return (await $api.post('/register', payload)).data;
    }

    async getUserIP(): Promise<IUserIPResponse> {
        return (await $api.get('/get_ip')).data;
    }

    async getCompetitionHistory(): Promise<IUserCompetitionHistory[]> {
        return (await $api.get('/history')).data;
    }

    async getUserCompetitionModerationList(): Promise<IUserCompetitionModerationList> {
        return (await $api.get('/profiles')).data;
    }

    async setUserLink(payload: ISetUserLinkPayload): Promise<IApiResponse> {
        return (await $api.put('/links', payload)).data;
    }
}

export const UserService = new userService();
